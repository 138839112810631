var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    [
      _c("sidebar", {
        ref: "sidebar",
        attrs: { collapse: _vm.device == "mobile" },
      }),
      _c(
        "el-container",
        [
          _c(
            "el-main",
            [
              _c("div", { staticClass: "app-header" }, [
                _c("div", { staticClass: "title" }, [_vm._v("SIMPLESTART.AI")]),
                _c("div", { staticClass: "description" }, [
                  _vm._v("简单启动AI，体验人工智能带来的简单与高效"),
                ]),
              ]),
              _c(
                "el-row",
                { staticClass: "app-filter", attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { sm: 20, xs: 20, offset: 2 } },
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "app-search",
                          attrs: {
                            size: "mini",
                            placeholder: "请输入内容",
                            clearable: "",
                          },
                          on: { clear: _vm.onSearch, change: _vm.onSearch },
                          model: {
                            value: _vm.pageFilter.keyword,
                            callback: function ($$v) {
                              _vm.$set(_vm.pageFilter, "keyword", $$v)
                            },
                            expression: "pageFilter.keyword",
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "el-input__icon el-icon-search",
                            attrs: { slot: "prefix" },
                            slot: "prefix",
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticClass: "margin-bottom", attrs: { sm: 24 } },
                    [
                      _c(
                        "div",
                        { staticClass: "flex justify-center flex-wrap" },
                        _vm._l(_vm.categoryList, function (item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "margin-xs" },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "margin-bottom",
                                  attrs: {
                                    type:
                                      _vm.pageFilter.category_id == item.id
                                        ? "primary"
                                        : "default",
                                    label: item.name,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onCategoryChange(item.id)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(item.title))]
                              ),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass:
                        "flex align-center justify-end margin-bottom",
                      attrs: { md: 24 },
                    },
                    [
                      _c(
                        "el-dropdown",
                        {
                          staticClass: "margin-left-xs",
                          on: { command: _vm.onSort },
                        },
                        [
                          _c(
                            "el-button",
                            { attrs: { type: "default", size: "medium" } },
                            [
                              _vm._v(" 排序 "),
                              _c("i", {
                                staticClass:
                                  "el-icon-arrow-down el-icon--right",
                              }),
                            ]
                          ),
                          _c(
                            "el-dropdown-menu",
                            { attrs: { slot: "dropdown" }, slot: "dropdown" },
                            [
                              _c(
                                "el-dropdown-item",
                                { attrs: { command: "time" } },
                                [_vm._v("最新时间")]
                              ),
                              _c(
                                "el-dropdown-item",
                                { attrs: { command: "popular" } },
                                [_vm._v("最受欢迎")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "app-list", attrs: { gutter: 30 } },
                [
                  _vm.pageData.data.length == 0
                    ? _c("el-empty", { attrs: { description: "暂无记录" } })
                    : _vm._l(_vm.pageData.data, function (item, index) {
                        return _c(
                          "el-col",
                          {
                            key: index,
                            attrs: { span: 24, lg: 8, md: 12, sm: 12 },
                          },
                          [
                            _c(
                              "el-card",
                              {
                                staticClass: "app-item",
                                attrs: {
                                  shadow: "hover",
                                  "body-style": { padding: "0px" },
                                },
                              },
                              [
                                _c("div", { staticClass: "app-info" }, [
                                  _c("h3", [_vm._v(_vm._s(item.title))]),
                                  _c(
                                    "div",
                                    { staticClass: "app-meta" },
                                    [
                                      _c("el-rate", {
                                        attrs: {
                                          value: item.rating || 5.0,
                                          disabled: "",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "app-summary text-content text-df text-gray",
                                    },
                                    [_vm._v(_vm._s(item.summary) + " ")]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "app-features" },
                                    _vm._l(
                                      item.features,
                                      function (feat, fidx) {
                                        return _c(
                                          "el-tag",
                                          {
                                            key: fidx,
                                            staticClass: "margin-xs",
                                            attrs: {
                                              type: "primary",
                                              effect: "plain",
                                              size: "mini",
                                            },
                                          },
                                          [_vm._v(_vm._s(feat))]
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "app-actions flex justify-around align-center",
                                  },
                                  [
                                    item.favorite
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "success",
                                              size: "small",
                                              icon: "el-icon-star-on",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.onFavorite(index)
                                              },
                                            },
                                          },
                                          [_vm._v("已收藏")]
                                        )
                                      : _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "success",
                                              size: "small",
                                              icon: "el-icon-star-off",
                                              plain: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.onFavorite(index)
                                              },
                                            },
                                          },
                                          [_vm._v("收藏")]
                                        ),
                                    item.type == "chat"
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "primary",
                                              size: "small",
                                              icon: "el-icon-chat-line-round",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.onCheckout(item)
                                              },
                                            },
                                          },
                                          [_vm._v("使用")]
                                        )
                                      : item.type == "image"
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "primary",
                                              size: "small",
                                              icon: "el-icon-copy-document",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.onCopy(item)
                                              },
                                            },
                                          },
                                          [_vm._v("复制")]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      }),
                ],
                2
              ),
              _c(
                "div",
                { staticClass: "margin flex justify-center align-center" },
                [
                  _c("el-pagination", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.pageData.total > 0,
                        expression: "pageData.total > 0",
                      },
                    ],
                    attrs: {
                      "current-page": _vm.pageData.page,
                      "page-size": _vm.pageData.per_page,
                      total: _vm.pageData.total,
                      background: "",
                      layout: "total, pager, prev, next",
                    },
                    on: { "current-change": _vm.onPageChange },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-footer"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }