var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    [
      _c("sidebar", {
        ref: "sidebar",
        attrs: { collapse: _vm.device == "mobile" },
        model: {
          value: _vm.conversation,
          callback: function ($$v) {
            _vm.conversation = $$v
          },
          expression: "conversation",
        },
      }),
      _c(
        "el-container",
        [
          _c(
            "div",
            { staticClass: "chat-setting" },
            [
              _c("prompt", {
                ref: "prompt",
                on: { change: _vm.changePrompt },
                model: {
                  value: _vm.conversation.prompt_id,
                  callback: function ($$v) {
                    _vm.$set(_vm.conversation, "prompt_id", $$v)
                  },
                  expression: "conversation.prompt_id",
                },
              }),
              _c(
                "div",
                {
                  staticClass:
                    "flex align-center margin-tb-sm hidden-sm-and-down",
                },
                [
                  _c("div", { staticClass: "margin-right-sm text-lg" }, [
                    _vm._v("插件:"),
                  ]),
                  _c(
                    "el-button",
                    { attrs: { type: "default", size: "mini" } },
                    [
                      _vm._v(" 暂无插件"),
                      _c("i", {
                        staticClass: "el-icon-arrow-down el-icon--right",
                      }),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-main",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.pageLoading,
                  expression: "pageLoading",
                },
              ],
              staticClass: "chat-container",
            },
            [
              _vm.messages.length
                ? _c(
                    "el-scrollbar",
                    {
                      ref: "scrollbar",
                      staticClass: "chat-msg-container",
                      attrs: { native: false },
                    },
                    _vm._l(_vm.messages, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          class: ["msg-container", "msg-" + item.role],
                        },
                        [
                          _c("role", {
                            attrs: {
                              role: item.role,
                              name: item.user_name,
                              avater: item.user_avatar,
                            },
                          }),
                          _c("message", {
                            attrs: {
                              content: item.content,
                              loading: item.loading,
                            },
                            on: { scroll: _vm.autoScroll },
                          }),
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _c(
                    "div",
                    {
                      staticClass:
                        "chat-container flex justify-center align-center flex-direction",
                    },
                    [
                      _c("div", { staticClass: "text-center" }, [
                        _c("h2", { staticClass: "margin-0" }, [
                          _vm._v("Simplestart.Ai"),
                        ]),
                        _c(
                          "p",
                          { staticClass: "margin-0 margin-top-xs text-grey" },
                          [_vm._v("基于最新ChatGPT人工智能")]
                        ),
                      ]),
                    ]
                  ),
            ],
            1
          ),
          _c(
            "el-footer",
            { staticClass: "chat-input-container", attrs: { height: "110px" } },
            [
              _c("div", { staticClass: "text-left text-xs delete" }, [
                _c(
                  "span",
                  { staticClass: "pointer", on: { click: _vm.clearMessage } },
                  [_vm._v("点此清除记录,")]
                ),
                _c("span", [_vm._v("按Shift+Enter可换行")]),
              ]),
              _c("el-input", {
                ref: "textarea",
                staticClass: "textarea",
                attrs: {
                  type: "textarea",
                  resize: "none",
                  disabled: _vm.loading,
                  autosize: { minRows: 2, maxRows: 6 },
                  placeholder: _vm.loading
                    ? "消息已发送，请稍等..."
                    : "几乎无所不知，请输入问题...",
                },
                nativeOn: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.sendMessage($event)
                  },
                },
                model: {
                  value: _vm.inputMessage,
                  callback: function ($$v) {
                    _vm.inputMessage = $$v
                  },
                  expression: "inputMessage",
                },
              }),
              _c("i", {
                staticClass: "el-icon-s-promotion icon",
                on: { click: _vm.sendMessage },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }