"use strict";

var _interopRequireDefault = require("D:/www/cms/app/ai/base/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createPrompt = createPrompt;
exports.getPromptList = getPromptList;
exports.removePrompt = removePrompt;
exports.updatePrompt = updatePrompt;
var _request = _interopRequireDefault(require("@/utils/request"));
// 这里放ai应用的指令接口

function getPromptList() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _request.default)({
    url: '/ai/prompt/list',
    method: 'get',
    params: params
  });
}
function createPrompt(data) {
  return (0, _request.default)({
    url: '/ai/prompt/create',
    method: 'post',
    data: data
  });
}
function updatePrompt(data) {
  return (0, _request.default)({
    url: '/ai/prompt/update',
    method: 'post',
    data: data
  });
}
function removePrompt(data) {
  return (0, _request.default)({
    url: '/ai/prompt/remove',
    method: 'post',
    data: data
  });
}