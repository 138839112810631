var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        title: "应用详情",
        width: "50%",
        "before-close": _vm.beforeClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "app-info felx align-start" }, [
        _c(
          "div",
          { staticClass: "app-image" },
          [
            _c("el-image", { attrs: { src: _vm.app.image } }, [
              _c(
                "div",
                {
                  staticClass: "image-error",
                  attrs: { slot: "error" },
                  slot: "error",
                },
                [_c("i", { staticClass: "el-icon-picture-outline" })]
              ),
            ]),
          ],
          1
        ),
        _c("div", { staticClass: "app-meta" }, [
          _c("h2", { staticClass: "app-name" }, [_vm._v(_vm._s(_vm.app.name))]),
          _c("p", { staticClass: "app-price" }, [
            _vm._v("价格：" + _vm._s(_vm._f("priceFilter")(_vm.app.price))),
          ]),
          _c(
            "p",
            { staticClass: "app-features" },
            [
              _vm._v("特性： "),
              _vm._l(_vm.app.features, function (feature, index) {
                return _c(
                  "el-tag",
                  {
                    key: index,
                    staticClass: "margin-xs",
                    attrs: { type: "primary", effect: "plain", size: "mini" },
                  },
                  [_vm._v(_vm._s(feature))]
                )
              }),
            ],
            2
          ),
          _c("p", { staticClass: "app-fav-count" }, [
            _vm._v("收藏人数：" + _vm._s(_vm.app.fav_count)),
          ]),
          _c(
            "div",
            { staticClass: "app-actions flex align-center" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "small",
                    icon: "el-icon-s-promotion",
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.gotoWebsite(_vm.app)
                    },
                  },
                },
                [_vm._v("访问")]
              ),
              _vm.app.favorite
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        type: "success",
                        size: "small",
                        icon: "el-icon-star-on",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.onFavorite(_vm.app)
                        },
                      },
                    },
                    [_vm._v("已收藏")]
                  )
                : _c(
                    "el-button",
                    {
                      attrs: {
                        type: "success",
                        size: "small",
                        icon: "el-icon-star-off",
                        plain: "",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.onFavorite(_vm.app)
                        },
                      },
                    },
                    [_vm._v("收藏")]
                  ),
            ],
            1
          ),
        ]),
      ]),
      _c("div", { staticClass: "margin-bottom text-content text-df" }, [
        _vm._v(_vm._s(_vm.app.description)),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }