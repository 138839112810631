"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _document = document,
  body = _document.body;
var WIDTH = 992; // refer to Bootstrap's responsive design
var _default = {
  data: function data() {
    return {
      device: 'destop'
    };
  },
  beforeMount: function beforeMount() {
    window.addEventListener('resize', this.$_resizeHandler);
  },
  beforeDestroy: function beforeDestroy() {
    window.removeEventListener('resize', this.$_resizeHandler);
  },
  mounted: function mounted() {
    this.device = this.$_isMobile() ? 'mobile' : 'destop';
  },
  methods: {
    $_isMobile: function $_isMobile() {
      var rect = body.getBoundingClientRect();
      return rect.width - 1 < WIDTH;
    },
    $_resizeHandler: function $_resizeHandler() {
      if (!document.hidden) {
        this.device = this.$_isMobile() ? 'mobile' : 'destop';
      }
    }
  }
};
exports.default = _default;