"use strict";

var _interopRequireDefault = require("D:/www/cms/app/ai/base/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _SidebarItem = _interopRequireDefault(require("./SidebarItem.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Sidebar',
  components: {
    SidebarItem: _SidebarItem.default
  },
  data: function data() {
    return {
      visible: true,
      typeList: [{
        name: 'all',
        title: '全部指令'
      }, {
        name: 'chat',
        title: '对话指令'
      }, {
        name: 'image',
        title: '画图指令'
      }, {
        name: 'owner',
        title: '我的发布'
      }, {
        name: 'favorite',
        title: '我的收藏'
      }]
    };
  },
  props: {
    collapse: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    collapse: function collapse(val) {
      this.visible = !val;
    }
  },
  computed: {
    classObj: function classObj() {
      return {
        hide: !this.visible
      };
    },
    active: function active() {
      var query = this.$router.history.current.query;
      if (query && query.type) {
        return query.type;
      }
      return 'all';
    }
  },
  created: function created() {},
  methods: {
    toggleSideBar: function toggleSideBar() {
      this.visible = !this.visible;
    },
    handleSelect: function handleSelect(e) {
      var query = this.$router.history.current.query;
      var path = this.$router.history.current.path;
      var newQuery = JSON.parse(JSON.stringify(query));
      if (e == 'all') {
        delete newQuery.type;
      } else {
        newQuery.type = e;
      }
      this.$router.push({
        path: path,
        query: newQuery
      }, function () {});
    }
  }
};
exports.default = _default;