"use strict";

var _interopRequireDefault = require("D:/www/cms/app/ai/base/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.create = create;
exports.getInfo = getInfo;
exports.getList = getList;
exports.getPage = getPage;
exports.remove = remove;
exports.removeAll = removeAll;
exports.update = update;
exports.updateList = updateList;
var _request = _interopRequireDefault(require("@/utils/request"));
function getPage() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _request.default)({
    url: '/ai/conversation/page',
    method: 'get',
    params: params
  });
}
function getList() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _request.default)({
    url: '/ai/conversation/list',
    method: 'get',
    params: params
  });
}
function getInfo() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _request.default)({
    url: '/ai/conversation/info',
    method: 'get',
    params: params
  });
}
function create(data) {
  return (0, _request.default)({
    url: '/ai/conversation/create',
    method: 'post',
    data: data
  });
}
function update(data) {
  return (0, _request.default)({
    url: '/ai/conversation/update',
    method: 'post',
    data: data
  });
}
function updateList(data) {
  return (0, _request.default)({
    url: '/ai/conversation/updateList',
    method: 'post',
    data: data
  });
}
function remove(data) {
  return (0, _request.default)({
    url: '/ai/conversation/remove',
    method: 'post',
    data: data
  });
}
function removeAll(data) {
  return (0, _request.default)({
    url: '/ai/conversation/removeAll',
    method: 'post',
    data: data
  });
}