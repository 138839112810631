"use strict";

var _interopRequireDefault = require("D:/www/cms/app/ai/base/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/www/cms/app/ai/base/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {},
  data: function data() {
    return {
      article: {
        content: "Markdown Write",
        html: "",
        title: "",
        tag: ""
      },
      isShowEditor: false
    };
  },
  computed: {},
  methods: {
    markdown2Html: function markdown2Html() {
      var _this = this;
      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('showdown'));
      }).then(function (showdown) {
        var converter = new showdown.Converter();
        _this.article.html = converter.makeHtml(_this.content);
      });
    },
    submit: function submit(status) {
      this.article.status = status;
      this.$message("\u6DFB\u52A0\u6210\u529F");
    }
  },
  mounted: function mounted() {
    this.isShowEditor = true;
  }
};
exports.default = _default;