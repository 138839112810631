"use strict";

var _interopRequireDefault = require("D:/www/cms/app/ai/base/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _clipboard = _interopRequireDefault(require("@/utils/clipboard"));
require("github-markdown-css/github-markdown.css");
require("highlight.js/styles/atom-one-dark.css");
var _markdownItHighlightjs = _interopRequireDefault(require("markdown-it-highlightjs"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// use clipboard directly

//引入一种语法的高亮

var MarkDownIt = require("markdown-it");
var hljs = require('highlight.js/lib/core');
var md = new MarkDownIt({
  linkify: true,
  typographer: true,
  highlight: function highlight(code, lang) {
    return hljs.highlight(code, {
      language: lang || 'javascript'
    });
  }
});
md.use(_markdownItHighlightjs.default, {
  hljs: hljs,
  inline: true
});
var _default = {
  components: {},
  data: function data() {
    return {
      contentHtml: '',
      first: true,
      showMsg: true
    };
  },
  props: {
    content: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    content: {
      handler: function handler(val) {
        var _this = this;
        if (val) {
          this.contentHtml = md.render(val) + (this.loading ? '<span class="flicker"></span>' : '');
          this.$nextTick(function () {
            _this.$emit('scroll');
          });
        }
      },
      immediate: true
    }
  },
  methods: {
    handeShow: function handeShow() {
      this.showMsg = !this.showMsg;
    },
    handleCopy: function handleCopy(text, event) {
      (0, _clipboard.default)(text, event);
    }
  }
};
exports.default = _default;