"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    role: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    avatar: {
      type: String,
      default: ''
    }
  },
  computed: {
    user: function user() {
      return {
        role: this.role ? this.role : 'assistant',
        name: this.name ? this.name : this.role == 'user' ? 'YOU' : 'ChatGPT',
        avatar: this.avatar
      };
    }
  }
};
exports.default = _default;