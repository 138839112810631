var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "app-sidebar", class: _vm.classObj },
    [
      _c(
        "div",
        { staticClass: "hamburger", on: { click: _vm.toggleSideBar } },
        [_c("i", { staticClass: "el-icon-chat-line-round" })]
      ),
      _c(
        "el-scrollbar",
        { staticClass: "scrollbar-container" },
        [
          _c(
            "el-menu",
            {
              attrs: {
                "default-active": _vm.active,
                "unique-opened": false,
                "collapse-transition": false,
                mode: "vertical",
                "menu-trigger": "click",
              },
              on: { select: _vm.handleSelect },
            },
            [
              _vm._l(_vm.chatList, function (item, index) {
                return _c(
                  "el-menu-item",
                  { key: index, attrs: { index: item.conversation_id } },
                  [
                    _c("i", {
                      class: item.loading
                        ? "el-icon-loading"
                        : "el-icon-chat-square",
                    }),
                    item.editable
                      ? _c("el-input", {
                          ref: "chatInput",
                          refInFor: true,
                          attrs: { placeholder: "请输入内容" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                            },
                            change: _vm.handleChange,
                            blur: _vm.handleBlur,
                          },
                          model: {
                            value: item.title,
                            callback: function ($$v) {
                              _vm.$set(item, "title", $$v)
                            },
                            expression: "item.title",
                          },
                        })
                      : _c(
                          "div",
                          {
                            staticClass: "title text-cut",
                            attrs: { slot: "title" },
                            slot: "title",
                          },
                          [_vm._v(_vm._s(item.title || "New Chat"))]
                        ),
                    !item.editable
                      ? _c(
                          "div",
                          { staticClass: "actions" },
                          [
                            _c("i", {
                              staticClass: "el-icon-edit",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.handleEdit(index)
                                },
                              },
                            }),
                            _c(
                              "el-popconfirm",
                              {
                                attrs: { title: "确定删除对话及所有消息吗？" },
                                on: {
                                  confirm: function ($event) {
                                    return _vm.handleRemove(index)
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-delete",
                                  attrs: { slot: "reference" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                    },
                                  },
                                  slot: "reference",
                                }),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                )
              }),
              _c(
                "el-menu-item",
                [
                  _c("el-button", {
                    staticClass: "new-chat",
                    attrs: { size: "medium", icon: "el-icon-plus" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.handleCreate($event)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c("div", { staticClass: "app-sidebar-footer" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }