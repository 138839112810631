"use strict";

var _interopRequireDefault = require("D:/www/cms/app/ai/base/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _components = require("./components");
var _Resize = _interopRequireDefault(require("./mixin/Resize"));
//
//
//
//
//
//
//
var _default = {
  name: 'Layout',
  data: function data() {
    return {};
  },
  components: {
    AppMain: _components.AppMain,
    AppSidebar: _components.AppSidebar
  },
  mixins: [_Resize.default]
};
exports.default = _default;