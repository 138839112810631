"use strict";

var _interopRequireDefault = require("D:/www/cms/app/ai/base/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "AppMain", {
  enumerable: true,
  get: function get() {
    return _AppMain.default;
  }
});
Object.defineProperty(exports, "AppSidebar", {
  enumerable: true,
  get: function get() {
    return _AppSidebar.default;
  }
});
var _AppMain = _interopRequireDefault(require("./AppMain"));
var _AppSidebar = _interopRequireDefault(require("./AppSidebar"));