var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "app-sidebar", class: _vm.classObj },
    [
      _c(
        "el-scrollbar",
        { staticClass: "scrollbar-container" },
        [
          _c(
            "el-menu",
            {
              attrs: {
                "default-active": _vm.active,
                collapse: _vm.collapse,
                "unique-opened": false,
                "collapse-transition": false,
                mode: "vertical",
                "menu-trigger": "click",
              },
              on: { select: _vm.handleSelect },
            },
            _vm._l(_vm.category, function (item, index) {
              return _c(
                "el-menu-item",
                { key: index, attrs: { index: item.name } },
                [
                  _c("sidebar-item", {
                    attrs: {
                      icon: item.icon,
                      title: item.title,
                      collapse: _vm.collapse,
                    },
                  }),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "app-sidebar-footer" }, [
        _c(
          "div",
          { staticClass: "hamburger", on: { click: _vm.toggleSideBar } },
          [
            _c("i", {
              class: _vm.collapse ? "el-icon-s-fold" : "el-icon-s-unfold",
            }),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }