var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "flex align-center margin-tb-sm" },
        [
          _c("div", { staticClass: "margin-right-sm text-lg" }, [
            _vm._v("指令:"),
          ]),
          _c(
            "el-dropdown",
            {
              staticClass: "margin-right-xs",
              attrs: { trigger: "click", placement: "bottom-start" },
              on: { command: _vm.handleChange },
            },
            [
              _c("el-button", { attrs: { type: "default", size: "mini" } }, [
                _vm._v(" " + _vm._s(_vm.promptSelected || "AI 助理")),
                _c("i", { staticClass: "el-icon-arrow-down el-icon--right" }),
              ]),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown", size: "mini" }, slot: "dropdown" },
                _vm._l(_vm.promptList, function (item, index) {
                  return _c(
                    "el-dropdown-item",
                    { key: index, attrs: { command: item.id } },
                    [
                      _c("div", { staticClass: "prompt flex padding-sm" }, [
                        _c("div", { staticClass: "title text-cut" }, [
                          _vm._v(_vm._s(item.title)),
                        ]),
                        _c("div", { staticClass: "summary text-cut" }, [
                          _vm._v(_vm._s(item.summary)),
                        ]),
                        item.id
                          ? _c(
                              "div",
                              { staticClass: "actions" },
                              [
                                _c("i", {
                                  staticClass: "el-icon-edit",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.handleUpdate(index)
                                    },
                                  },
                                }),
                                _c(
                                  "el-popconfirm",
                                  {
                                    attrs: { title: "确定删除该指令吗" },
                                    on: {
                                      confirm: function ($event) {
                                        return _vm.handleRemove(index)
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "el-icon-delete",
                                      attrs: { slot: "reference" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                        },
                                      },
                                      slot: "reference",
                                    }),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c("el-button", {
            attrs: { icon: "el-icon-plus", size: "mini" },
            on: {
              click: function ($event) {
                _vm.formVisable = true
              },
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.formVisable, title: "自定义指令" },
          on: {
            "update:visible": function ($event) {
              _vm.formVisable = $event
            },
            open: _vm.onOpen,
            close: _vm.onClose,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "model",
              attrs: {
                model: _vm.formData,
                rules: _vm.formRules,
                size: "medium",
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "名称", prop: "title" } },
                [
                  _c("el-input", {
                    style: { width: "100%" },
                    attrs: { placeholder: "请输入名称/标题", clearable: "" },
                    model: {
                      value: _vm.formData.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "title", $$v)
                      },
                      expression: "formData.title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "简介", prop: "summary" } },
                [
                  _c("el-input", {
                    style: { width: "100%" },
                    attrs: { placeholder: "请输入指令简介", clearable: "" },
                    model: {
                      value: _vm.formData.summary,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "summary", $$v)
                      },
                      expression: "formData.summary",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Prompt", prop: "content" } },
                [
                  _c("el-input", {
                    style: { width: "100%" },
                    attrs: {
                      type: "textarea",
                      placeholder: "请输入指令内容(提示词)",
                      autosize: { minRows: 4, maxRows: 4 },
                    },
                    model: {
                      value: _vm.formData.content,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "content", $$v)
                      },
                      expression: "formData.content",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否公开", prop: "publish" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { size: "medium" },
                      model: {
                        value: _vm.formData.publish,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "publish", $$v)
                        },
                        expression: "formData.publish",
                      },
                    },
                    _vm._l(_vm.publishOptions, function (item, index) {
                      return _c(
                        "el-radio",
                        {
                          key: index,
                          attrs: { label: item.value, disabled: item.disabled },
                        },
                        [_vm._v(_vm._s(item.label))]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.formVisable = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleSubmit } },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }