var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-container" }, [
      _c("div", { staticClass: "message" }, [
        _c("div", { staticClass: "message__oops" }, [_vm._v("AI正在学习...")]),
        _c("div", { staticClass: "message__info" }, [
          _vm._v(
            "我们正在努力推进工作，让您能够尽情享受未来的精彩，敬请期待！"
          ),
        ]),
      ]),
      _c("div", { staticClass: "copyright" }, [
        _vm._v("Powered by "),
        _c(
          "a",
          { attrs: { href: "http://www.startcms.cn", target: "_blank" } },
          [_vm._v("StartCMS")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }