var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-container" }, [
    _vm._m(0),
    _c(
      "div",
      [
        _c(
          "el-image",
          { staticClass: "margin-tb", attrs: { src: _vm.scan.qrcode } },
          [
            _c(
              "div",
              {
                staticClass: "image-slot",
                attrs: { slot: "error" },
                slot: "error",
              },
              [_c("i", { staticClass: "el-icon-picture-outline" })]
            ),
          ]
        ),
        _c("div", [_vm._v(_vm._s(_vm.scan.status))]),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "message" }, [
      _c("div", { staticClass: "message__oops" }, [_vm._v("AI微信机器人...")]),
      _c("div", { staticClass: "message__info" }, [
        _vm._v("我们正在努力推进工作，让您能够尽情享受未来的精彩，敬请期待！"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }