var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "message-container" }, [
    _c("div", { staticClass: "message-content" }, [
      _vm.showMsg
        ? _c("div", { domProps: { innerHTML: _vm._s(_vm.contentHtml) } })
        : _vm._e(),
    ]),
    _c(
      "div",
      { staticClass: "message-handle" },
      [
        _c("el-tooltip", { attrs: { placement: "top", content: "复制" } }, [
          _c(
            "div",
            {
              staticClass: "btn",
              on: {
                click: function ($event) {
                  return _vm.handleCopy(_vm.contentHtml, $event)
                },
              },
            },
            [_c("i", { staticClass: "el-icon-document-copy" })]
          ),
        ]),
        _c(
          "el-tooltip",
          {
            attrs: { placement: "top", content: _vm.showMsg ? "隐藏" : "显示" },
          },
          [
            _c("div", { staticClass: "btn", on: { click: _vm.handeShow } }, [
              _c("i", { staticClass: "el-icon-view" }),
            ]),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }