"use strict";

var _interopRequireDefault = require("D:/www/cms/app/ai/base/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getQrcode = getQrcode;
exports.getSetting = getSetting;
exports.updateSetting = updateSetting;
var _request = _interopRequireDefault(require("@/utils/request"));
function getQrcode() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _request.default)({
    url: '/wechat/qrcode',
    method: 'get',
    params: params
  });
}
function getSetting() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _request.default)({
    url: '/wechat/setting',
    method: 'get',
    params: params
  });
}
function updateSetting(data) {
  return (0, _request.default)({
    url: '/wechat/updateSetting',
    method: 'post',
    data: data
  });
}