var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "role-container" },
    [
      _vm.user.avatar
        ? _c("el-avatar", { attrs: { size: 24, src: _vm.user.avatar } })
        : _vm.user.role == "user"
        ? _c(
            "svg",
            {
              staticClass: "icon",
              attrs: {
                t: "1680252681776",
                viewBox: "0 0 1024 1024",
                version: "1.1",
                xmlns: "http://www.w3.org/2000/svg",
                "p-id": "2751",
                width: "24px",
                height: "24px",
              },
            },
            [
              _c("path", {
                attrs: {
                  d: "M512 51.2a460.8 460.8 0 1 1 0 921.6 460.8 460.8 0 0 1 0-921.6zM302.6432 801.6384a335.0528 335.0528 0 0 0-14.4384 35.5328A394.9568 394.9568 0 0 0 512 906.9568a398.8992 398.8992 0 0 0 222.5152-68.4544c-3.9424-10.5472-7.936-21.0432-12.544-30.9248a235.6736 235.6736 0 0 0-419.328-5.9392z m484.5056-7.2192l3.328-0.6656a394.9568 394.9568 0 1 0-551.68 0c2.6112-8.192 5.9392-16.128 9.8816-23.7056a300.8512 300.8512 0 0 1 531.2512 6.6048c3.2768 5.888 7.2192 12.4928 7.2192 17.7664zM347.4432 413.184a164.5568 164.5568 0 1 0 329.1136 0 164.5568 164.5568 0 0 0-329.1136 0zM512 512a98.7648 98.7648 0 1 1 0-197.4784A98.7648 98.7648 0 0 1 512 512z",
                  "p-id": "2752",
                },
              }),
            ]
          )
        : (_vm.user.role = "assistant")
        ? _c(
            "svg",
            {
              attrs: {
                id: "openai-horizontal",
                xmlns: "http://www.w3.org/2000/svg",
                width: "22px",
                height: "22px",
                viewBox: "0 0 32 32",
                "enable-background": "new 0 0 32 32",
              },
            },
            [
              _c("path", {
                attrs: {
                  fill: "currentColor",
                  d: "M27.21,12.08c.67-2.01,.44-4.21-.63-6.04-1.61-2.8-4.85-4.24-8.01-3.57C17.16,.89,15.14-.01,13.02,0c-3.23,0-6.1,2.08-7.1,5.15-2.08,.43-3.87,1.73-4.92,3.57-1.62,2.8-1.25,6.32,.92,8.72-.67,2.01-.44,4.21,.63,6.03,1.61,2.81,4.85,4.25,8.02,3.58,1.4,1.58,3.42,2.49,5.54,2.48,3.23,0,6.1-2.08,7.1-5.15,2.08-.43,3.87-1.73,4.91-3.57,1.63-2.8,1.26-6.32-.91-8.72Zm-2.3-5.07c.64,1.12,.88,2.43,.66,3.7-.04-.03-.12-.07-.17-.1l-5.88-3.4c-.3-.17-.67-.17-.97,0l-6.89,3.98v-2.92l5.69-3.29c2.65-1.53,6.03-.62,7.56,2.03Zm-13.25,6.07l2.9-1.68,2.9,1.68v3.35l-2.9,1.68-2.9-1.68v-3.35ZM13.01,1.93c1.3,0,2.55,.45,3.55,1.28-.04,.02-.12,.07-.18,.1l-5.88,3.39c-.3,.17-.48,.49-.48,.84v7.96l-2.53-1.46V7.46c0-3.06,2.47-5.53,5.53-5.54ZM2.68,9.69h0c.65-1.12,1.66-1.98,2.88-2.43v6.99c0,.35,.18,.66,.48,.84l6.88,3.97-2.54,1.47-5.68-3.28c-2.64-1.53-3.55-4.91-2.02-7.56Zm1.55,12.83h0c-.65-1.11-.88-2.43-.66-3.7,.04,.03,.12,.07,.17,.1l5.88,3.4c.3,.17,.67,.17,.97,0l6.88-3.98v2.92l-5.69,3.28c-2.65,1.52-6.03,.62-7.56-2.02Zm11.89,5.08c-1.29,0-2.55-.45-3.54-1.28,.04-.02,.13-.07,.18-.1l5.88-3.39c.3-.17,.49-.49,.48-.84v-7.95l2.53,1.46v6.57c0,3.06-2.48,5.54-5.53,5.54Zm10.34-7.76c-.65,1.12-1.67,1.98-2.88,2.42v-6.99c0-.35-.18-.67-.48-.84h0l-6.89-3.98,2.53-1.46,5.69,3.28c2.65,1.53,3.55,4.91,2.02,7.56Z",
                },
              }),
            ]
          )
        : _vm._e(),
      _c("div", { staticClass: "margin-left-xs text-lg" }, [
        _vm._v(_vm._s(_vm.user.name)),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }