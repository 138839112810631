"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'SidebarItem',
  functional: true,
  props: {
    icon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    collapse: {
      type: Boolean,
      default: false
    }
  },
  render: function render(h, context) {
    var _context$props = context.props,
      icon = _context$props.icon,
      title = _context$props.title,
      collapse = _context$props.collapse;
    var vnodes = [];
    vnodes.push(h('span', {
      slot: 'title'
    }, [title]));
    vnodes.push(h('i', {
      class: 'el-icon-edit-outline'
    }));
    vnodes.push(h('i', {
      class: 'el-icon-close'
    }));
    return vnodes;
  }
};
exports.default = _default;