var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-main" },
    [
      _vm._m(0),
      _c(
        "el-row",
        { staticClass: "app-filter", attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { md: 16, sm: 8, xs: 24 } },
            [
              _c(
                "el-radio-group",
                {
                  staticClass: "hidden-sm-and-down",
                  attrs: { size: "mini" },
                  model: {
                    value: _vm.pricing,
                    callback: function ($$v) {
                      _vm.pricing = $$v
                    },
                    expression: "pricing",
                  },
                },
                _vm._l(_vm.pricingList, function (item, index) {
                  return _c(
                    "el-radio-button",
                    { key: index, attrs: { label: item.name } },
                    [_vm._v(_vm._s(item.title))]
                  )
                }),
                1
              ),
              _c(
                "el-dropdown",
                {
                  staticClass: "hidden-md-and-up margin-bottom",
                  on: { command: _vm.onSort },
                },
                [
                  _c(
                    "el-button",
                    { attrs: { type: "default", size: "mini" } },
                    [
                      _vm._v(" 价格 "),
                      _c("i", {
                        staticClass: "el-icon-arrow-down el-icon--right",
                      }),
                    ]
                  ),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    _vm._l(_vm.pricingList, function (item, index) {
                      return _c(
                        "el-dropdown-item",
                        { key: index, attrs: { command: item.name } },
                        [_vm._v(_vm._s(item.title))]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-dropdown",
                { staticClass: "margin-left-xs", on: { command: _vm.onSort } },
                [
                  _c(
                    "el-button",
                    { attrs: { type: "default", size: "mini" } },
                    [
                      _vm._v(" 排序 "),
                      _c("i", {
                        staticClass: "el-icon-arrow-down el-icon--right",
                      }),
                    ]
                  ),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _c("el-dropdown-item", { attrs: { command: "time" } }, [
                        _vm._v("最新时间"),
                      ]),
                      _c(
                        "el-dropdown-item",
                        { attrs: { command: "popular" } },
                        [_vm._v("最受欢迎")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { md: 8, sm: 16, xs: 24 } },
            [
              _c(
                "el-input",
                {
                  staticClass: "app-search",
                  attrs: {
                    size: "mini",
                    placeholder: "请输入内容",
                    clearable: "",
                  },
                  on: { clear: _vm.onSearch, change: _vm.onSearch },
                  model: {
                    value: _vm.keyword,
                    callback: function ($$v) {
                      _vm.keyword = $$v
                    },
                    expression: "keyword",
                  },
                },
                [
                  _c("i", {
                    staticClass: "el-input__icon el-icon-search",
                    attrs: { slot: "prefix" },
                    slot: "prefix",
                  }),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "app-center", attrs: { gutter: 30 } },
        [
          _vm.pageData.data.length == 0
            ? _c("el-empty", { attrs: { description: "暂无记录" } })
            : _vm._l(_vm.pageData.data, function (item, index) {
                return _c(
                  "el-col",
                  { key: index, attrs: { span: 24, lg: 8, md: 12, sm: 12 } },
                  [
                    _c(
                      "el-card",
                      {
                        staticClass: "app-item",
                        attrs: {
                          shadow: "hover",
                          "body-style": { padding: "0px" },
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "app-cover pointer",
                            on: {
                              click: function ($event) {
                                return _vm.onShowInfo(item)
                              },
                            },
                          },
                          [
                            _c("el-image", { attrs: { src: item.image } }, [
                              _c(
                                "div",
                                {
                                  staticClass: "image-error",
                                  attrs: { slot: "error" },
                                  slot: "error",
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-picture-outline",
                                  }),
                                ]
                              ),
                            ]),
                            _c("span", { staticClass: "app-price" }, [
                              _vm._v(_vm._s(_vm._f("priceFilter")(item.price))),
                            ]),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "app-info" }, [
                          _c("h3", [_vm._v(_vm._s(item.name))]),
                          _c(
                            "div",
                            { staticClass: "app-meta" },
                            [
                              _c("el-rate", {
                                attrs: {
                                  value: item.rating || 5.0,
                                  disabled: "",
                                },
                              }),
                              _c("span", { staticClass: "app-collect" }, [
                                _vm._v(_vm._s(item.fav_count) + "人收藏"),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "app-summary text-content text-df" },
                            [_vm._v(_vm._s(item.description))]
                          ),
                          _c(
                            "div",
                            { staticClass: "app-features" },
                            _vm._l(item.features, function (feat, fidx) {
                              return _c(
                                "el-tag",
                                {
                                  key: fidx,
                                  staticClass: "margin-xs",
                                  attrs: {
                                    type: "primary",
                                    effect: "plain",
                                    size: "mini",
                                  },
                                },
                                [_vm._v(_vm._s(feat))]
                              )
                            }),
                            1
                          ),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass:
                              "app-actions flex justify-around align-center",
                          },
                          [
                            item.favorite
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "success",
                                      size: "small",
                                      icon: "el-icon-star-on",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.onFavorite(index)
                                      },
                                    },
                                  },
                                  [_vm._v("已收藏")]
                                )
                              : _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "success",
                                      size: "small",
                                      icon: "el-icon-star-off",
                                      plain: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.onFavorite(index)
                                      },
                                    },
                                  },
                                  [_vm._v("收藏")]
                                ),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  size: "small",
                                  icon: "el-icon-s-promotion",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.gotoWebsite(item)
                                  },
                                },
                              },
                              [_vm._v("访问")]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                )
              }),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "margin flex justify-center align-center" },
        [
          _c("el-pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.pageData.total > 0,
                expression: "pageData.total > 0",
              },
            ],
            attrs: {
              "current-page": _vm.pageData.page,
              "page-size": _vm.pageData.per_page,
              total: _vm.pageData.total,
              background: "",
              layout: "total, pager, prev, next",
            },
            on: { "current-change": _vm.onPageChange },
          }),
        ],
        1
      ),
      _c("app-info", { ref: "appInfo", attrs: { app: _vm.currentApp } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "app-header" }, [
      _c("div", { staticClass: "title" }, [_vm._v("SIMPLESTART.AI")]),
      _c("div", { staticClass: "description" }, [
        _vm._v("发现全球最新AI应用，每日更新"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }